<template>
  <main class="ml-12">
    <div class="wrapper" v-if="allRegisters">
    <div class="flex-col mt-12 mb-8">
      <h2>Sales</h2>
      <h3 class="font-bold mt-4 text-2xl">Registers</h3>
    </div>
    <div class=" bg-white  rounded-lg overflow-hidden big-shadow">
      <div class="mt-8 mb-5 mx-8 justify-between flex flex-wrap">
        <div class="flex flex-column w-full">
          <div class="flex flex-row items-center">
            <trac-dropdown class="mr-5" :menuItems="menuItems"></trac-dropdown>
            <div class="flex flex-row items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8.16267"
                  cy="8.16267"
                  r="7.16267"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.116 17.116L13.2213 13.2213"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label class="ml-3 text-xs font-bold text-primaryBlue"
                >SEARCH</label
              >
            </div>
          </div>
        </div>
        <div class="flex flex-column w-full mt-10">
          <div class=" grid grid-cols-2 gap-8 w-full">
            <div class="flex flex-column">
              <div class="flex-wrap w-full">
                <div class="mb-8 ">
                  <h1 class="uppercase font-medium">
                    Open <span class="font-bold">Registers</span>
                  </h1>
                  <svg
                    width="20"
                    height="4"
                    viewBox="0 0 20 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
                      fill="url(#paint0_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="-9.46895"
                        y1="2.21242"
                        x2="-7.84897"
                        y2="9.88217"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#06B6CB" />
                        <stop offset="1" stop-color="#253B95" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div class=" mb-8 border rounded-lg overflow-hidden ">
                  <table class=" w-full table-fixed">
                    <thead class="bg-blue-100 ">
                      <tr class="h-20">
                        <th
                          class="text-left text-sm font-semibold uppercase pl-8"
                        >
                          Register
                        </th>
                        <th
                          class="text-left text-xs font-semibold uppercase pl-8"
                        >
                          status
                        </th>
                        <th class="text-left text-xs font-semibold uppercase">
                          Amount in register
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                        v-for="(register, index) in openedRegisters"
                        class="h-20 cursor-pointer"
                        :key="index"
                        @click="gotoRegisterDetails(register)"
                      >
                        <td class="text-sm font-bold capitalize pl-8">
                          {{ register.name }}
                        </td>
                        <td class="text-xs font-medium capitalize pl-8">
                          Open
                        </td>
                        <td class=" text-xs font-medium ">
                          {{ register.amount | formatPrice }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="flex flex-column border-l pl-8">
              <div class="flex-wrap w-full">
                <div class="mb-8 ">
                  <h1 class="uppercase font-medium">
                    Closed <span class="font-bold">Registers</span>
                  </h1>
                  <svg
                    width="20"
                    height="4"
                    viewBox="0 0 20 4"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
                      fill="url(#paint0_linear)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="-9.46895"
                        y1="2.21242"
                        x2="-7.84897"
                        y2="9.88217"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#06B6CB" />
                        <stop offset="1" stop-color="#253B95" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div class=" mb-8 border rounded-lg overflow-hidden ">
                  <table class=" w-full table-fixed">
                    <thead class="bg-blue-100">
                      <tr class="h-20">
                        <th
                          class="text-left text-sm font-semibold uppercase pl-8"
                        >
                          Register
                        </th>
                        <th
                          class="text-left text-xs font-semibold uppercase pl-8"
                        >
                          status
                        </th>
                        <th class="text-left text-xs font-semibold uppercase">
                          Amount in register
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                        v-for="(register, index) in closedRegisters"
                        class="h-20 cursor-pointer"
                        :key="index"
                        @click="gotoRegisterDetails(register)"
                      >
                        <td class="text-sm font-bold capitalize pl-8">
                          {{ register.name }}
                        </td>
                        <td class="text-xs font-medium capitalize pl-8">
                          Closed
                        </td>
                        <td class="text-xs font-medium ">
                          {{ register.amount | formatPrice }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="w-full h-screen" v-else>
      <trac-loading />
    </div>
  </main>
</template>

<script>
import { SAVE_LOCAL_DB_DATA } from '../../browser-db-config/localStorage';
export default {
  name: "Registers",
  data() {
    return {
      stores: null,
      selectedStore: null,
      allRegisters: null,
      menuItems: [
        { name: "filter1", value: 1 },
        { name: "filter2", value: 2 },
        { name: "filter3", value: 3 },
        { name: "filter4", value: 4 },
      ],
      openedRegisters: [],
      closedRegisters: [],
    };
  },
  async created() {
    await this.fetchAllStores();
    await this.fetchAllRegisters();
  },
  methods: {
    // Stores
    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      const res = this.$store.getters["GET_ALL_STORES"];

      if (res.status) {
        this.stores = res.data || [];
      }
    },
    // Registers
    async fetchAllRegisters() {
      this.selectedStore = this.stores[0];

      await this.$store.dispatch("FETCH_ALL_REGISTERS", this.selectedStore);
      const res = this.$store.getters["GET_ALL_REGISTERS"];

      if (res.status) {
        this.allRegisters = res.data || [];
        this.allRegisters.forEach((register) => {
          if (register.opened) {
            this.openedRegisters.push(register);
          } else {
            this.closedRegisters.push(register);
          }
        });
      }
    },
    gotoRegisterDetails(register) {
      SAVE_LOCAL_DB_DATA("register-details", register);
      this.$router.push({ name: 'RegistersDetails' });
    }
  },
};
</script>

<style scoped></style>
